import { mapState } from 'vuex'
import mz from '@mezereon/tracking'
import { setCustomCSS, convertCurrency } from '@/helpers'

export default {
  data() {
    return {
      loading: true,
      apiUrl: mz.config.search.url,
      searchKey: mz.config.search.key
    }
  },
  methods: {
    runAutocomplete(keyword, skipSuggestions, resultsCount) {
      const query = {
        keyword: keyword,
        page: 1,
        pageSize: resultsCount,
        sort: '_score',
        includeBanners: false
      }
      if (this.context.currency) {
        query['currency'] = this.context.currency
      }
      const autocompleteConfig = {
        currentComplete: this.complete,
        currentSuggestion: this.currentSuggestion,
        includeSuggestResult: !skipSuggestions,
        includeSearchResult: true
      }
      const context = {
        visitId: mz ? mz.getVisitId() : '',
        visitorId: mz ? mz.getVisitorId() : ''
      }
      const autocomplete = {
        query: query,
        autocompleteConfig: autocompleteConfig,
        context: context
      }
      this.$store
        .dispatch('search/runAutocomplete', autocomplete, { root: true })
        .then(() => {
          convertCurrency('.mz-autocomplete-container .money')

          // emit after-autocomplete event needed for custom handlers i.e. custom multi-currency
          if (this.$bus) this.$bus.emit('after-autocomplete')
        })
    }
  },
  computed: {
    ...mapState('search', [
      'config',
      'primaryKey',
      'context',
      'fallbackMode',
      'complete',
      'currentSuggestion'
    ])
  },
  created() {
    this.$store.dispatch('api/setApiUrl', this.apiUrl, { root: true })
    this.$store.dispatch('api/setSearchKey', this.searchKey, { root: true })

    if (this.$bus) this.$bus.on('run-autocomplete', this.runAutocomplete)
  },
  async mounted() {
    if (this.fallbackMode) {
      this.loading = false
      return
    }
    await this.$store.dispatch('search/loadConfig', {}, { root: true })
    if (this.fallbackMode) {
      this.loading = false
      this.turnOnFallbackMode()
      return
    } else {
      setCustomCSS('autocomplete.css', this.config.css)
    }
  }
}
