var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-recommendations',_vm._b({scopedSlots:_vm._u([{key:"recommendation-item",fn:function(ref){
    var template = ref.template;
    var item = ref.item;
    var queryId = ref.queryId;
    var source = ref.source;
    var layout = ref.layout;
    var index = ref.index;
    var primaryKey = ref.primaryKey;
    var recommendationClick = ref.recommendationClick;
return [_c('recommendation-item',{attrs:{"template":template,"item":item,"query-id":queryId,"source":source,"layout":layout,"index":index,"primary-key":primaryKey},on:{"recommendation-click":recommendationClick}})]}}])},'base-recommendations',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }