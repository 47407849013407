import { search } from '@/store/search.js'
import { api } from '@/store/api.js'
import { history } from '@/store/history.js'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
  reducer: state => ({
    history: state.history
  })
})

const Store = new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  modules: {
    search,
    api,
    history
  }
})

export default {
  install(Vue, options) {
    Vue.prototype.$store = Store

    // make axios and queryState accessible inside store
    Store.$axios = Vue.prototype.$axios
    Store.$queryState = Vue.prototype.$queryState
  }
}
