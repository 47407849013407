<template>
  <svg viewBox="0 0 512 512">
    <path
      d="M496 203.3H312.36L256 32l-56.36 171.3H16l150.21 105.4l-58.5 171.3L256 373.84L404.29 480l-58.61-171.3z"
    ></path>
  </svg>
</template>

<script>
export default { name: 'MzStar3' }
</script>
